
























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
@Component({
  components: {}
})
export default class P0909 extends Vue {
  //QA表示・非表示トグル動作（DOMを直接操作している）
  toggleAnswer(e: Event) {
    let target = e.target as HTMLElement;
    let targetParent = target.parentNode as HTMLElement;
    let targetBox = targetParent.nextSibling as HTMLElement;
    if (target.getAttribute('aria-expanded') === 'true') {
      target.setAttribute('aria-expanded', 'false');
      targetBox.setAttribute('aria-hidden', 'true');
    } else {
      target.setAttribute('aria-expanded', 'true');
      targetBox.setAttribute('aria-hidden', 'false');
    }
  }

  //ページが下にスクロールされたかの判定フラグ
  is_scroll_down = false;

  mounted() {
    var isScrollReady = false;
    var scroll_buffer = 0;

    const eventScroll = () => {
      if (isScrollReady) {
        return false;
      }

      isScrollReady = true;

      window.requestAnimationFrame(() => {
        this.is_scroll_down = scroll_buffer < window.scrollY ? true : false;
        scroll_buffer = window.scrollY;

        isScrollReady = false;
      });
    };

    window.addEventListener('scroll', () => {
      eventScroll();
    });

    eventScroll();
  }
}
